body {
  background-color: #F5F5F5;
}

@font-face {
  font-family: 'Dohyeon';
  src: url('../public/fonts/DoHyeon-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Exo';
  src: url('../public/fonts/Exo2-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../public/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.mainContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 10px;
  gap: 10px;
}


h1 {
  font-family: Exo, sans-serif;
  font-size: 52px;
  color: white;
  letter-spacing: 2px;
  text-align: center;
}

h2 {
  font-family: Exo;
  font-size: 25px;
  font-weight: lighter;
  color: #ffffff;
  letter-spacing: 1px;
}

h3 {
  font-family: Montserrat;
  font-size: 17px;
  color: #333333;
}

h4 {
  font-family: Montserrat;
  font-size: 14px;
  color: #333333;
}

.mainRow
{
  display: flex;
  flex-flow: row wrap;
  align-items: start;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.disclaimerCard {
  display: flex;
  flex-flow: column nowrap;
  max-width: 1064px;
  width: 100%;
  height: fit-content;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #4CAF50;
  overflow: hidden;
  box-shadow: 0 9px 8px rgba(0, 0, 0, 0.12);
  margin-bottom: 20px; 
  margin-top: 30px;
}

.disclaimerCardBody {
  padding: 5px 20px;
}

.disclaimerCardBody li{
  font-family: Montserrat;
  font-size: 14px;
}

.disclaimerCardBody a{
  text-decoration: none;
}

.header {
  background-color: #4CAF50;
  color: #ffffff;
  padding: 20px; 
  text-align: center; 
  border-radius: 0 0 8px 8px ; 
  margin-bottom: 30px; 
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2); 
  letter-spacing: 2px; 
  text-transform: uppercase;
  display: flex;
  flex-flow: column nowrap;
}

.header h1,h4{
  margin: 5px;
}

@media (max-width: 1100px) {

  .header h1{
    font-size: 48px;
  }
}

.cardColumn
{
  display: flex;
  flex-flow: column nowrap;
}

.cardRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  text-align: end;
}

@media (max-width: 500px) {
  .cardRow{
      text-align: start;
  }
  h3 {
    font-size: 16px;
  }
}

.navbar {
  background-color: #4CAF50;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}

.navbar li {
  margin-right: 20px;
}

.navbar a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  transition: opacity 0.3s ease;
}

.navbar a:hover {
  opacity: 0.8;
}

/* Active link style */
.navbar a.active {
  border-bottom: 2px solid white;
}

.blogs-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.blogs-container h1 {
  color: #4CAF50;
}

.blog-list {
  display: grid;
  gap: 20px;
}

.blog-preview {
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.blog-preview h2 {
  margin-top: 0;
}

.blog-preview a {
  color: #4CAF50;
  text-decoration: none;
}

.blog-preview a:hover {
  text-decoration: underline;
}

.blog-post {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  overflow: hidden;
}

.blog-post h1 {
  color: #4CAF50;
  font-size: 30px;
}

.blog-post h2 {
  color: #1e1e1e;
}