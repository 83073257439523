.detailsCard
{
    display: flex;
    flex-flow: column nowrap;
    max-width: 644px;
    width: 100%;
    height: fit-content;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add this line for box shadow */
    margin-bottom: 20px; /* Optional: Add some margin to separate cards */
}

.detailsCardHeader
{
    display: flex;
    align-items: center;
    background-color: #4CAF50;
    width: 100%;
    height: 49px;
    border-radius: 10px 10px 0px 0px;
}

.detailsCardBody
{
    padding: 10px;
}