.breakdownCard {
    display: flex;
    flex-flow: column nowrap;
    max-width: 1064px;
    width: 100%;
    height: fit-content;
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add this line for box shadow */
    margin-bottom: 20px; /* Optional: Add some margin to separate cards */

}

.breakdownCardHeader {
    display: flex;
    align-items: center;
    background-color: #4CAF50;
    width: 100%;
    height: 49px;
    border-radius: 10px 10px 0 0;
}

.breakdownCardBody {
    padding: 0px;
}

.breakdownTable {
    width: 100%;
    border-collapse: collapse;
    font-family: Montserrat, sans-serif;
}

.breakdownTable th, .breakdownTable td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.breakdownTable th {
    background-color: #f2f2f2;
}

/* size of first column */
.breakdownTable th:first-child, .breakdownTable td:first-child {
    width: 30%;
}

/* size of other columns */
.breakdownTable th:not(:first-child), .breakdownTable td:not(:first-child) {
    width: 20%;
}

.netSalaryRow td {
    background-color: #f2f2f2;
    font-size: 18px;
    font-weight: 900;
}

.greenText
{
    font-weight: 600;
    color: #46a249;
}

.redText
{
    font-weight: 600;
    color: #F5392C;
}

/* Desktop styles */
.breakdownDesktop {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.breakdownMobile {
    display: none;
}

/* Mobile styles */
@media (max-width: 1100px) {
    .breakdownDesktop {
        display: none;
    }

    .breakdownMobile {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-flow: column nowrap;
        align-items: center;
    }

    .breakdownCard {
        margin-bottom: 20px;
        max-width: 644px;
    }
}